<template>
  <div
    class="flex items-center px-6 py-3 fixed bg-light z-10 top-0 inset-x-0 border-b border-gray300 justify-between"
    :style="{ height: `${headerHeight}px` }"
  >
    <slot>
      <button
        class="w-8 mr-2 flex sm:hidden"
        @click="useSettingsModal().openMobileSideBar()"
      >
        <Icon name="menu" />
      </button>
      <div class="hidden sm:block md:w-[20%] max-w-[14.5rem]">
        <img
          src="@/assets/images/logo.svg"
          alt="logo"
          class="md:h-[36.31px] h-[40px]"
        />
      </div>
    </slot>
    <div class="flex items-center gap-5">
      <Dropdown
        popper-class="-mt-1"
        trigger="click"
        @command="handleCommand"
        @visible-change="toggleDropdown"
      >
        <button class="flex gap-1.5 items-center">
          <World />
          <Typography
            variant="baseBold"
            class="uppercase"
            >{{ locale }}</Typography
          >
          <icon
            name="downArrow"
            :class="['w-5 !text-secondary duration-300', isOpen ? 'rotate-180' : '']"
          />
        </button>
        <template #dropdown>
          <DropdownItem
            v-for="item in locales"
            :key="item.code"
            :command="item.code"
          >
            {{ item.name }}
          </DropdownItem>
        </template>
      </Dropdown>
      <div
        ref="target"
        class="flex flex-col relative"
      >
        <button
          class="flex items-center gap-2.5"
          @click="toggleMenu"
        >
          <avatar
            :name="business?.name"
            :src="business.logo"
            :size="40"
            bg-color="bg-primary100"
            color="text-gray900"
          />
          <div class="flex flex-col">
            <span class="text-left text-sm font-semibold text-secondary"
              >{{ user.first_name }} {{ user.last_name }}</span
            >
            <span class="text-xs font-medium text-greyDark">{{ business?.name }}</span>
          </div>
          <icon
            name="downArrow"
            :class="['md:ml-5 w-5 !text-secondary duration-300', showMenu ? 'rotate-180' : '']"
          />
        </button>
        <transition
          name="slide"
          appear
          :duration="500"
        >
          <div
            v-if="showMenu"
            mode="out-in"
            class="bg-white z-[1000000000] px-4 bg-shadow text-sm font-light absolute top-[3.5rem] right-0 bsha border-divider w-[232px] rounded-[10px] pt-3 pb-4"
          >
            <div
              v-for="n in businessArr"
              :key="n.id"
              class="cursor-pointer flex flex-col items-start py-2 px-2 hover:rounded-[10px] text-gray950 hover:bg-divider transite relative border-b border-divider"
              @click="updateBusiness(n)"
            >
              <span class="font-semibold text-secondary text-base">{{ n.name }} </span>
              <span class="text-grey text-sm flex">
                {{ n.account_number }}
                <icon
                  name="copy"
                  class="w-4 ml-3 hover:scale-110 text-secondary cursor-pointer"
                  @click.stop="copyData(n.account_number, $t('copied'))"
                />
              </span>

              <icon
                v-if="business?.account_number === n.account_number"
                name="checkmark"
                class="w-5 absolute right-2 top-4 text-primary"
              />
            </div>
            <div
              v-if="businessMenu.length > 2"
              class="flex pb-1 pt-2.5 justify-end"
            >
              <div class="w-full flex justify-between">
                <span class="text-darkGray font-medium">{{ $t('more', { number: 3 }) }}</span>
                <button
                  class="font-semibold"
                  @click="showMoreValue ? showMore() : showLess()"
                >
                  {{ showMoreValue ? viewAll : viewLess }}
                </button>
              </div>
            </div>

            <div class="flex flex-col pb-1 pt-2.5 gap-4">
              <button
                v-if="businesses.length < 5 && hasAnApprovedBusiness"
                class="sub_link"
                @click="$router.push('/auth/business')"
              >
                <icon
                  name="add"
                  class="w-6 text-greyDark mr-4"
                />
                {{ $t('overview.newBusiness') }}
              </button>
              <nuxt-link
                class="sub_link"
                to="/settings/profile"
              >
                <icon
                  name="cog"
                  class="w-6 mr-4"
                />
                {{ $t('overview.settings') }}
              </nuxt-link>
              <div
                v-if="checkoutIsEnabled"
                class="sub_link"
                @click="openPayment()"
              >
                <icon
                  name="bank-transfer"
                  class="w-4 mr-4 fill-transparent"
                />
                {{ $t('overview.generatePayment') }}
              </div>
              <div
                class="sub_link"
                @click="openSupport()"
              >
                <icon
                  name="headphone"
                  class="w-6 mr-4"
                />
                {{ $t('overview.support') }}
              </div>
              <a
                href="https://maplerad.dev/"
                target="_blank"
                class="sub_link"
              >
                <icon
                  name="console"
                  class="w-6 mr-4 text-greyDark"
                />
                {{ $t('overview.developer') }}
              </a>
              <div
                class="sub_link"
                @click="useUser().logOut()"
              >
                <icon
                  name="signOut"
                  class="w-6 mr-4"
                />
                {{ $t('overview.logOut') }}
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core'
import World from '../icons/World.vue'
import { useBusiness } from '~/src/composables/apis/business'
import { useUser } from '~/src/composables/apis/user'
import { createAccount } from '~/src/composables/apis/account'
import { copyToClipboard } from '~/src/composables/utils/useFile/index'
import { useTransactionsModal, useSettingsModal } from '~/src/composables/core/modals'
import { useFeatureToggle, FeatureToggles } from '~/src/composables/utils/useFeatureToggle'
import { headerHeight } from '~/src/constants'
import { ComplianceRoutes } from '~/src/types/internals'

const checkoutIsEnabled = useFeatureToggle(FeatureToggles.Checkout)

const { locale, locales, setLocale } = useI18n()
const { updateLanguage } = createAccount()

const handleCommand = (item: string) => {
  updateLanguage(item, {
    onSuccess: () => {
      setLocale(item)
    },
  })
}

const INITIAL_LENGTH = ref(2)
const showMoreValue = ref(true)

const openSupport = () => {
  closeMenu()
  useSettingsModal().openSupport()
}

const openPayment = () => {
  closeMenu()
  useTransactionsModal().openPayment()
}

const { business, user, businesses, hasAnApprovedBusiness, updateSelectedBusiness } = useBusiness()
const { copyData } = copyToClipboard()

const target = ref(null)
const showMenu = ref(false)
const closeMenu = () => (showMenu.value = false)
const toggleMenu = () => (showMenu.value = !showMenu.value)
onClickOutside(target, closeMenu)

const isOpen = ref(false)
const toggleDropdown = () => (isOpen.value = !isOpen.value)

const updateBusiness = async (data: any) => {
  if (data.account_number === business?.value?.account_number) return
  await updateSelectedBusiness(data)
  toggleMenu()

  if (!business.value.live) {
    useRouter().push({
      name: ComplianceRoutes.index,
    })
  } else {
    location.reload()
  }
}

const businessMenu = computed(() => {
  const selectedBiz = businesses.value.filter((x: any) => {
    return x.id === business?.value?.id
  })[0]

  const reordered = businesses.value.filter((x: any) => {
    return x.id !== business?.value?.id
  })

  return [selectedBiz, ...reordered]
})

const businessArr = computed(() => {
  return businessMenu.value.slice(0, INITIAL_LENGTH.value)
})

const showMore = () => {
  INITIAL_LENGTH.value += businessMenu.value.length - INITIAL_LENGTH.value
  showMoreValue.value = false
}

const showLess = () => {
  INITIAL_LENGTH.value -= businessMenu.value.length - 2
  showMoreValue.value = true
}

const { t } = useI18n()
const viewAll = t('overview.viewAll')
const viewLess = t('overview.viewLess')
</script>

<style scoped>
.ml {
  margin-left: min(20%, 14.5rem);
}
.sub_link {
  @apply cursor-pointer flex items-center text-greyDark text-base font-medium;
}
.bsha {
  box-shadow: 0px 4px 16px rgba(31, 41, 55, 0.1);
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.2s ease;
}
.slide-enter-from,
.slide-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
